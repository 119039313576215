<template>
  <div>
    <toolbarTitle :title="header ? header : 'Orders'" class="mb-4"/>
    <v-row>
      <v-col cols="12" lg="3" md="12" sm="12" xs="12" class="pb-0">
        <v-text-field
            v-model.trim="filter.query"
            outlined
            clearable
            class="font-weight-bold"
            :autofocus="filter.query == null"
            prepend-inner-icon="mdi-magnify"
            label="Search by order ID..."
            hint="Search by Mango order ID or external booking ID"
            hide-details
            color="orange"
            v-on:keyup.enter="listOrders()"></v-text-field>
      </v-col>

      <v-col cols="12" lg="3" md="12" sm="12" xs="12" class="pb-0">
        <v-text-field
            v-model.trim="filter.customer_query"
            outlined
            clearable
            class="font-weight-bold"
            prepend-inner-icon="mdi-magnify"
            label="Search by customer details..."
            hint="Search by customer or passenger details (name, email, phone)"
            persistent-hint
            hide-details
            v-on:keyup.enter="listOrders()"></v-text-field>
      </v-col>

      <v-col cols="12" lg="2" md="12" class="pb-0">
        <v-menu
            ref="datepicker"
            v-model="datepicker"
            :close-on-content-click="false"
            :nudge-left="70"
            transition="scale-transition"
            min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="filter.dates"
                class="font-weight-bold"
                label="Order creation date"
                prepend-inner-icon="mdi-calendar"
                readonly
                outlined
                clearable
                v-bind="attrs"
                v-on="on"
                hide-details></v-text-field>
          </template>

          <v-date-picker v-model="filter.dates" :first-day-of-week="1" no-title range hide-details></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" lg="4" class="pb-0">
        <v-select
            outlined
            class="align-stretch font-weight-bold"
            v-model="selectedTenants"
            :items="tenants"
            item-text="name"
            chips
            item-value="id"
            :menu-props="{ maxHeight: '400' }"
            label="Select tenants"
            multiple
            hide-details
            :persistent-hint="false">
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="shouldShowChip(index)">
              <span>{{ item.name }}</span>
            </v-chip>
            <span v-if="index === 3" class="grey--text text-caption">
              (+{{ selectedTenants.length - shouldShowChipIndex() }})
            </span>
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" lg="12">
        <SearchFilters ref="childFilters"/>
      </v-col>
      <v-col cols="12" lg="12">
        <v-btn block color="accent" height="40" @click="listOrders()">SEARCH</v-btn>
      </v-col>
    </v-row>

    <!-- empty line-->
    <v-row>
      <v-col></v-col>
    </v-row>

    <h4 v-if="!searchedAtLeastOnce" style="display: table; margin: 0 auto">Start a search to see the orders.</h4>

    <v-data-table
        :mobile-breakpoint="1500"
        v-if="searchedAtLeastOnce"
        :headers="headers"
        :items="orders"
        :server-items-length="ordersTotalCount"
        :loading="loading"
        class="elevation-1 mt-2"
        :items-per-page="filter.itemsPerPage"
        :options.sync="filter"
        :footer-props="{
        'items-per-page-options': [30, 45, 100, 500],
      }">
      <template v-slot:[`item.id`]="{ item }">
        <div class="orderID">
          <div class="d-flex one-line">
            <OrderIDField :order="item"></OrderIDField>
            <OrderIconsField
                :order="item"
                :showPrintableInfo="true"
                :showDistributedInfo="true"
                :showGroupOrderInfo="true"
                :showClaimInfo="true"
                :showArchiveOrder="true"
                :isOverview="true"/>
          </div>
        </div>
      </template>

      <template v-slot:[`item.bookings`]="{ item }">
        <div
            v-for="(booking, i) in isOverview ? item.bookingExternalOrderIds : item.bookings"
            :key="i"
            class="bookingID">
          <div>
            <h4 class="grey--text text--darken-4" style="display: flex; flex-wrap: nowrap">
              <v-tooltip top :open-delay="10" :close-delay="5">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      color="mango-red"
                      @click="copyToClipboard(isOverview ? booking : booking.externalOrderId)"
                      small
                      v-bind="attrs"
                      v-on="on">
                    mdi-content-copy
                  </v-icon>
                </template>
                <span>Copy</span>
              </v-tooltip>
              <span class="ml-1 font-weight-bold">{{ isOverview ? booking : booking.externalOrderId }}</span>
            </h4>
          </div>
          <v-divider
              class="mr-7"
              v-if="i + 1 !== (isOverview ? item.bookingExternalOrderIds.length : item.bookings.length)"
              :key="i"></v-divider>
        </div>
      </template>

      <template v-slot:[`item.created`]="{ item }">
        <div class="dateTime">
          <dateTimeField :date="item.created" type="dateTime"></dateTimeField>
        </div>
      </template>

      <template v-slot:[`item.seller`]="{ item }">
        <div class="soldBY">
          <div class="text-truncate font-weight-medium">
            <v-tooltip bottom color="black">
              <template v-slot:activator="{ on }">
                <v-avatar width="20" height="20" tile min-width="30" class="mr-1" v-on="on">
                  <img
                      :src="
                      'https://assets.trainplanet.com/wlabel/' +
                      fixUrl(isOverview ? item.seller.tenantName : item.seller.tenant.name) +
                      '/logos/favicon-32x32.png'
                    "/>
                </v-avatar>
              </template>
              <span style="display: block; text-align: center">{{
                  isOverview ? item.seller.tenantName : item.seller.tenant.name
                }}</span>
            </v-tooltip>
            <span>{{ item.seller.userFullName ?? item.seller.user.name }}</span>
          </div>
        </div>
      </template>

      <template v-slot:[`item.customer`]="{ item }">
        <div v-if="item.customer.fullName" class="customer">
          <div class="text-truncate font-weight-medium">
            <v-icon
                v-if="isOverview ? item.isBusinessOrder : (item.invoiceId || item.customer.category === 'company')"
                class="mr-1"
                small>
              mdi-domain
            </v-icon>
            {{ item.customer.fullName }}
            <div v-if="item.customer">
              <v-divider></v-divider>
              <div v-if="item.customer">
                <div class="grey--text text-truncate">
                  <v-tooltip top :open-delay="10" :close-delay="5">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          color="mango-red"
                          @click="copyToClipboard(item.customer.email)"
                          small
                          v-bind="attrs"
                          v-on="on">
                        mdi-content-copy
                      </v-icon>
                    </template>
                    <span>Copy</span>
                  </v-tooltip>
                  <span class="ml-1 font-weight-medium">{{ item.customer.email }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="grey--text font-weight-medium">N/A</div>
      </template>

      <template v-slot:[`item.refundAmount`]="{ item }">
        <div class="refunded">
          <span class="one-line" :class="getColorBalance(item.status)">
            {{ isOverview ? item.refundAmountPlainText : item.cancelInformation.amount.plainText }}
          </span>
        </div>
      </template>

      <!--Validation Systems Icons-->
      <template v-slot:[`item.validityStatus`]="{ item }">
        <div class="validityHeader">
          <ValidationSystems
              :order-id="item.id"
              :validity="item.validity"
              :showTextCaption="false"
              v-bind:validity.sync="item.validity"/>
        </div>
      </template>

      <template v-slot:[`item.priceTotal`]="{ item }">
        <div class="totalPrice">
          <span class="font-weight-bold one-line">
            {{ isOverview ? item.totalPricePlainText : item.calculatedPrice.total.plainText }}
          </span>
        </div>
      </template>

      <template v-slot:[`item.isPaid`]="{ item }">
        <div class="status">
          <v-chip
              class="font-weight-bold cliporder"
              style="min-width: 60px; font-size: 12px; min-height: 27px; text-transform: capitalize"
              :class="getColorStatus(item.status)"
              link
              x-small>
            {{ item.status.replace("_", " ") }}
          </v-chip>
        </div>
      </template>
    </v-data-table>
    <v-progress-linear v-if="loading" indeterminate color="#f4846e"></v-progress-linear>

    <v-btn color="accent" :to="{ name: 'orders-create' }" fab large fixed dark bottom right>
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>
<script>
import OrderIDField from "@/components/common/OrderIDField";
import OrderIconsField from "@/components/common/OrderIconsField";
import dateTimeField from "@/components/common/dateTimeField";
import toolbarTitle from "@/components/common/toolbarTitle";
import router from "@/router";
import AccountApi from "@/services/account.api";
import store from "@/store";
import { copyToClipboard, fixUrl } from "@/util/helpers";
import Trainplanet from "@/util/trainplanet.api";
import ValidationSystems from "@/views/Orders/Order/ValidationSystems.vue";
import { mapGetters } from "vuex";
import common from "../../../mixins/common";
import SearchFilters from "../components/SearchFilters.vue";

Date.prototype.addDays = function (days) {
  this.setDate(this.getDate() + days);
  return this;
};

export default {
  name: "Search",
  components: {
    ValidationSystems,
    OrderIDField,
    OrderIconsField,
    SearchFilters,
    toolbarTitle,
    dateTimeField,
  },
  mixins: [common],
  props: {
    header: "",
    accountId: "",
    isAccount: false,
    isOverview: {
      type: Boolean,
      default: true,
    },
    dates: Array,
    status: String,
    query: String,
    customer_query: String,
    searchOnce: String, // actually boolean but since it is in link query, it's a string
  },
  data: () => ({
    token: "",
    orders: [],
    ordersTotalCount: 0,
    datepicker: false,
    selectedTenants: [],
    searchedAtLeastOnce: false,
    pageWatcherShouldReact: true,
    screenWidth: window.innerWidth,
    filter: {
      query: null,
      customer_query: null,
      dates: null,
      printable: false,
      paid: true,
      archived: true,
      excludeLisa: false,
      privateCustomer: false,
      companyCustomer: false,
      itemsPerPage: 30,
    },
    loading: false,
    headers: [
      {
        text: "Order ID",
        value: "id",
        sortable: false,
        width: 250,
      },
      {
        text: "Linkonline ID",
        value: "bookings",
        sortable: false,
      },
      {
        text: "Created",
        value: "created",
      },
      {
        text: "Sold by",
        value: "seller",
        sortable: false,
      },
      {
        text: "Customer",
        value: "customer",
        sortable: false,
        width: 100,
      },
      {
        text: "Refunded",
        value: "refundAmount",
        sortable: false,
        class: "refunded",
        align: "end",
      },
      {
        text: "Total Price",
        value: "priceTotal",
        sortable: false,
        align: "end",
        width: 100,
      },
      {
        text: "Validity",
        value: "validityStatus",
        sortable: false,
        width: 20,
      },
      {
        text: "Status",
        value: "isPaid",
        sortable: false,
        align: "center",
        width: 100,
      },
    ],
    selectedFilters: [
      {
        name: "Paid",
        key: "paid",
      },
    ],
    filterSelections: [
      {
        name: "Printable",
        key: "printable",
      },
      {
        name: "Paid",
        key: "paid",
      },
      {
        name: "Not Paid",
        key: "notPaid",
      },
      {
        name: "Exclude Lisa",
        key: "excludeLisa",
      },
      {
        name: "Only Lisa",
        key: "onlyLisa",
      },
      {
        name: "Private Customer",
        key: "privateCustomer",
      },
      {
        name: "Company Customer",
        key: "companyCustomer",
      },
      {
        name: "Archived",
        key: "archived",
      },
    ],
  }),

  // filters: {
  //   humanDatetime(datetime) {
  //     return moment.utc(datetime).local().fromNow();
  //   },
  //   getOnlyDate(datetime) {
  //     return moment.utc(datetime).local().format("YYYY-MM-DD");
  //   },
  //   getOnlyTime(datetime) {
  //     return moment.utc(datetime).local().format("HH:mm");
  //   },
  // },
  computed: {
    AccountModel() {
      return new AccountApi(this.tenant, this.token);
    },

    ...mapGetters({
      tenants: "auth/userTenants",
    }),
  },

  created() {
    this.getToken();
    // parse URL parameters
    if (this.query) {
      this.filter.query = this.query;
    }
    if (this.customer_query) {
      this.filter.customer_query = this.customer_query;
    }
    if (this.dates) {
      this.filter.dates = this.dates;
    }
  },
  mounted() {
    this.selectedTenants = this.tenants.map((item) => item.id);

    if (this.searchOnce === "true") {
      this.listOrders();
    }
    window.addEventListener("resize", this.updateScreenWidth);
  },
  watch: {
    "filter.page": {
      handler: function f(val) {
        if (this.pageWatcherShouldReact === true) {
          this.listOrders(false);
        }
      },
    },
    "filter.itemsPerPage": {
      handler: function f(val) {
        // FIXME filter.page and filter.itemsPerPage watchers get called at the same time when itemsPerPage is changed.
        // this causes double requests to the backend. fix it.
        if (this.pageWatcherShouldReact === true) {
          this.listOrders(false);
        }
      },
    },
    // filter: {
    //   deep: true,
    //   handler: debounce(function (val) {
    //     // if (this.searchedAtLeastOnce) {
    //     const params = this.getParams();
    //     this.listOrders(params);
    //     // }
    //   }, 500),
    // },
    //   selectedFilters: {
    //     deep: true,
    //     handler: debounce(function (val) {
    //       if (!this.searchedAtLeastOnce) {
    //         const params = this.getParams();

    //         this.listOrders(params);
    //       }
    //     }, 500),
    //   },
    // selectedTenants: {
    // deep: true,
    // handler: debounce(function () {
    // const params = this.getParams();

    // if (this.tenants.length > 0) {
    //   this.listOrders(params);
    // }
    // }, 500),
    // },
    tenants: {
      deep: true,
      handler: function (value) {
        this.selectedTenants = this.tenants.map((item) => item.id);
      },
    },
  },
  methods: {
    async getToken() {
      this.token = await store.dispatch("auth/acquireToken", router.history.current.meta.scopes);
      if (this.isAccount) {
        this.listOrders();
      }
    },

    fixUrl,
    copyToClipboard,
    getParams() {
      let params = {};
      const filterCookie = JSON.parse(localStorage.getItem("mango_search_filters"));
      if (filterCookie) {
        params = filterCookie.reduce((acc, curr) => {
          if (curr.selectedValue) {
            if (curr.selectedValue[0] === "explode") {
              for (const [key, value] of Object.entries(curr.selectedValue[1])) {
                acc[key] = value;
              }
            } else {
              acc[curr.selectedValue[0]] = curr.selectedValue[1];
            }
          }
          return acc;
        }, {});
      } else {
        params = { paid: true };
      }

      return params;
    },
    async listOrders(resetPage = true) {
      this.searchedAtLeastOnce = true;
      this.loading = true;

      if (resetPage === true) {
        this.pageWatcherShouldReact = false; // enable this back after fetching the orders, otherwise it's too fast for watcher to react
        this.filter.page = 1;
      }

      try {
        let [fromDate, untilDate] = this.filter.dates ?? [];

        if (fromDate && !untilDate) {
          const date = new Date(fromDate);
          untilDate = date.addDays(1).toISOString();
        }

        // make sure all filters are saved in storage
        this.$refs.childFilters.saveItemsToStorage();
        const params = {
          query: this.filter.query,
          customer_query: this.filter.customer_query,
          perPage: this.filter.itemsPerPage,
          page: this.filter.page,
          sortBy: this.filter.sortBy && this.filter.sortBy.length > 0 ? this.filter.sortBy[0] : null,
          sortDesc:
            !this.filter.sortDesc || !this.filter.sortDesc.length || !this.filter.sortBy || !this.filter.sortBy.length
              ? true
              : this.filter.sortDesc[0],
          fromDate,
          untilDate,
          tenantIds: this.selectedTenants,
          ...this.getParams(),
        };
        var response;
        if (!this.isAccount) {
          response = await Trainplanet.listOrders(params);
          this.orders = response.data;
          this.ordersTotalCount = parseInt(response.meta.count, 10);
        } else {
          if ("paid" in params) params.notPaid = !params.paid;
          response = await this.AccountModel.getOrders(params, this.accountId);
          this.orders = response.data.data;
          this.ordersTotalCount = parseInt(response.data.meta.count, 10);
        }
      } catch (error) {
        await this.$store.dispatch("error", error.message);
      } finally {
        this.loading = false;
        this.pageWatcherShouldReact = true; // do this after fetching the orders, otherwise it's too fast for watcher to react
      }
    },
    getColorBalance(status) {
      switch (status) {
        case "completed":
          return "grey--text  font-weight-bold";
        case "refunded":
          return "mango-red  font-weight-bold";
        case "partially_refunded":
          return "mango-orange  font-weight-bold";
        default:
          return "grey--text  font-weight-bold";
      }
    },
    getColorStatus(status) {
      switch (status) {
        case "completed":
          return "mango-green-status";
        case "refunded":
          return "mango-red-status ";
        case "partially_refunded":
          return "mango-orange-status ";
        case "pending":
          return "mango-gray-status  font-weight-bold";
        default:
          return "mango-orange-status ";
      }
    },
    getMaxChipsForScreenWidth(width) {
      if (width >= 1830) return 3;
      if (width >= 1410) return 2;
      if (width >= 1260) return 1;
      return 3;
    },
    shouldShowChip(index) {
      const maxChips = this.getMaxChipsForScreenWidth(this.screenWidth);
      return index < maxChips;
    },
    shouldShowChipIndex() {
      return this.getMaxChipsForScreenWidth(this.screenWidth);
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped lang="scss">
.secondary.red {
  background-color: red !important;
}

.minw100 {
  min-width: 100px;
}

.v-chip {
  min-width: 130px;
  text-align: center;
  justify-content: center;
}

.tickettooltip {
  background-color: #fac557;
}

.notpaid {
  background-color: #f4846e !important;
  color: #753a2e !important;
}

.paid {
  background-color: #9bce85 !important;
  color: #447242 !important;
}

.one-line {
  white-space: nowrap;
}

.v-select__selections {
  min-height: 56px !important;
}

.tooltip {
  position: relative;
  display: inline-block;
}

@media screen and (min-width: 1500px) {
  .orderID {
    max-width: 13vw;
  }

  .bookingID {
    max-width: 5vw;
  }

  .dateTime {
    max-width: 7vw;
  }

  .soldBY {
    max-width: 7vw;
  }

  .customer {
    max-width: 10vw;
  }

  .validityHeader {
    max-width: 2vw;
  }

  .totalPrice {
    max-width: 5vw;
  }

  .status {
    max-width: 10vw;
  }
}
</style>
