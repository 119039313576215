<template>
  <div>
    <toolbarTitle title="Printed Tickets" color="greeny" />
    <v-data-table
      fixed-header
      height="500px"
      :headers="printedOrdersHeaders"
      :items="orders"
      :server-items-length="ordersTotalCount"
      :loading="loading"
      class="elevation-1"
      :items-per-page="filter.itemsPerPage"
      :options.sync="filter"
      :footer-props="{
        'items-per-page-options': [20, 50, 100],
      }">
      <template v-slot:item="{ item }">
        <tr :class="item.distribution.isLocked && 'grey lighten-4'">
          <td :class="item.distribution.isLocked && 'disableCss'">
            <div class="d-flex flex-row" style="width: 180px">
              <OrderIDField :order="item"></OrderIDField>
              <OrderIconsField
                :order="item"
                :showPrintableInfo="false"
                :showDistributedInfo="true"
                :showGroupOrderInfo="true"
                :showClaimInfo="true"
                :showArchiveOrder="true"
                :isOverview="true"></OrderIconsField>
            </div>
          </td>
          <td :class="item.distribution.isLocked && 'disableCss'">
            <OrderPrintDeliverField :order="item" />
          </td>
          <td :class="item.distribution.isLocked && 'disableCss'">
            <div>
              <b>{{ item.purchaseDate | localizeUTCAndGetDate }}</b>
              {{ item.purchaseDate | localizeUTCAndGetTime }}
            </div>
          </td>
          <td :class="item.distribution.isLocked && 'disableCss'" class="pl-0">
            <orderCustomerField :order="item" :address="true" />
          </td>

          <td :class="item.distribution.isLocked && 'disableCss'" style="min-width: 160px">
            <earliestDepartureDateField :order="item" />
          </td>
          <td style="min-width: 160px">
            <div class="d-flex flex-column">
              <b class="threeDots" style="max-width: 120px">
                {{ item.distribution.printedByFullName ? item.distribution.printedByFullName : "" }}</b
              >
              <span class="grey--text text--darken-1">
                {{ item.distribution.printedDate ? item.distribution.printedDate : "" }}</span
              >
            </div>
          </td>
          <td style="min-width: 160px">
            <div class="d-flex align-center">
              <v-tooltip color="black" bottom style="cursor: pointer" v-if="item.internalMessage">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="mango-red" class="mr-1" small>
                    mdi-message-reply-text
                  </v-icon>
                  <span
                    v-bind="attrs"
                    v-on="on"
                    :class="item.distribution.isLocked && 'disableCss'"
                    class="threeDots"
                    style="max-width: 170px">
                    {{ item.internalMessage | cleanHtml }}</span
                  >
                </template>
                <div v-if="item.internalMessage" style="max-width: 400px">
                  {{ item.internalMessage | cleanHtml }}
                </div>
              </v-tooltip>
            </div>
          </td>
          <td style="min-width: 190px">
            <v-row>
              <v-col md="auto" class="d-flex justify-start align-center w-100" style="width: 100%; display: flex">
                <v-tooltip color="black" bottom style="cursor: pointer">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-btn
                        :disabled="!item.distribution.trackingId"
                        :loading="loadingButton === `cancel_button_${item.id}`"
                        @click="
                          loadingButton = `cancel_button_${item.id}`;
                          cancelDistribution(item);
                        "
                        color="primary"
                        small>
                        CANCEL TO RE-PRINT
                      </v-btn>
                    </div>
                  </template>
                  <div>Cancel for re-print.</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-progress-linear v-if="loading" indeterminate color="#f4846e"></v-progress-linear>
  </div>
</template>

<script>
import printOrderMixin from "../mixins/printOrder";
import Trainplanet from "@/util/trainplanet.api";
import OrderIDField from "@/components/common/OrderIDField";
import OrderIconsField from "@/components/common/OrderIconsField";
import OrderPrintDeliverField from "@/components/common/OrderPrintDeliverField";
import earliestDepartureDateField from "@/components/common/earliestDepartureDateField";
import toolbarTitle from "@/components/common/toolbarTitle";
import orderCustomerField from "@/components/common/orderCustomerField";
import common from "@/mixins/common";

export default {
  mixins: [printOrderMixin, common],
  props: ["refreshPrintedOrders", "refreshPrintableOrdersCallback"],
  components: {
    OrderPrintDeliverField,
    orderCustomerField,
    earliestDepartureDateField,
    toolbarTitle,
    OrderIDField,
    OrderIconsField,
  },
  data: () => ({
    printedOrdersHeaders: [
      {
        text: "Order ID",
        value: "id",
        sortable: false,
        width: "140px",
      },
      {
        text: "Delivery method",
        value: "distribution.title",
        sortable: false,
        width: "120px",
      },
      {
        text: "Purchase Date",
        value: "created",
        sortable: false,
        width: "150px",
      },
      {
        text: "Customer",
        value: "customer",
        sortable: false,
        width: "300px",
        class: "pl-0",
      },

      {
        text: "Earliest Departure Date",
        value: "earliestDepartureDate",
        sortable: false,
        width: "180px",
      },

      {
        text: "Printed By",
        value: "datetime",
        sortable: false,
        width: "160px",
      },
      {
        text: "Internal Message",
        value: "distribution.comment",
        sortable: false,
        width: "100px",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "left",
        width: "150px",
      },
    ],
    loading: false,
    printedTickets: true,
    loadingButton: false,
  }),
  watch: {
    refreshPrintedOrders(val) {
      if (val) {
        this.listOrders();
      }
    },
    filter(val) {
      if (val) {
        this.listOrders();
      }
    },
  },
  methods: {
    getPrintedBy(events) {
      return events.filter((data) => data.code === "ORDER_PRINTED")[0];
    },
    async cancelDistribution(item) {
      this.$confirm("Are you sure you want to cancel the selected printed ticket?", {
        color: "error",
        title: "Printed Tickets",
        width: 500,
      }).then(async (res) => {
        if (!res) {
          this.loadingButton = "";
        } else {
          const itemId = item.distribution.id;
          const orderId = item.id;

          try {
            await Trainplanet.cancelDistribution(orderId, itemId);

            await this.$store.dispatch("success", "Distribution cancelled successfully");

            await this.refreshPrintableOrdersCallback();
            await this.listOrders();
          } catch (err) {
            await this.$store.dispatch("error", err.response.data.message || err);
          }

          this.loadingButton = "";
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  text-transform: none !important;
}
</style>
