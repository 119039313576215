const orderNumberPattern = /[a-zA-Z][a-zA-Z][a-zA-Z][0-9][0-9][0-9][0-9][a-zA-Z]/;
const emailPattern = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
const phonePattern = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
const postalCodePattern = /^\d{5}(?:[-\s]\d{4})?$/;
const greaterZeroCheckPattern = /^[1-9][0-9]*$/;

const validator = {
  greaterZeroCheck() {
    return [
      v => !!v || "This field is required",
      v => greaterZeroCheckPattern.test(v) || "Number must be greater than zero"
    ];
  },
  select() {
    return [v => !!v || "Please select an option"];
  },
  required() {
    return [v => !!v || "This field is required"];
  },
  orderNumber() {
    return [
      v => !!v || "Order Number is required",
      v => orderNumberPattern.test(v) || "Order Number must be valid"
    ];
  },
  email() {
    return [
      v => !!v || "E-mail is required",
      v => emailPattern.test(v) || "The email field must be a valid email"
    ];
  },
  phone() {
    return [v => !!v || "This field is required"];
    // return [v => phonePattern.test(v) || "Phone number must be valid"];
  },
  postalCode() {
    return [v => postalCodePattern.test(v) || "Postal code number must be valid"];
  },

  // Wiki validation
  wikiTitle() {
    return [
      v => !!v || "Title is required",
      v => (v = v || "").length <= 30 || 'Title must be less than 30 characters'
    ];
  },
  wikiDescription() {
    return [
      v => (v = v || "").length <= 1000 || 'Description must be less than 1000 characters'
    ];
  }
};

function debounce(
  fn,
  delay,
  additionalKey = null, // this is used for different objects that share the same instance
  executeNow = false // this is used if this function is already wrapped inside a function
) {
  let timeoutID = null;
  let _additionalKey = additionalKey;

  const func = function(...args) {
    if (additionalKey === null || additionalKey === _additionalKey) {
      clearTimeout(timeoutID);
      const that = this;
      timeoutID = setTimeout(() => {
        fn.apply(that, args);
      }, delay);
    }
  };

  if (executeNow) {
    func();
  } else {
    return func;
  }
}

function makeCopy(object) {
  return JSON.parse(JSON.stringify(object));
}

function copyToClipboard(data) {
  navigator.clipboard.writeText(data);
  this.onSuccess(`Copied: ${data}`);
}

function fixUrl(x) {
  let val = x ? x : "";
  return val
    .toString()
    .trim()
    .toLowerCase()
    .replace(/-/g, " ")
    .replace(".", "")
    .replace(/ /g, "-")
    .replace(/å/g, "a")
    .replace(/ä/g, "a")
    .replace(/ń/g, "n")
    .replace(/ó/g, "o")
    .replace(/ö/g, "o")
    .replace(/ü/g, "u")
    .replace(/ğ/g, "g")
    .replace(/ç/g, "ci");
}

function getLegacySupportedBookingsFromInquiry(inquiry) {
  let ret = [];

  let id = 0;
  for (const order of inquiry.orders) {
    for (const booking of order.bookings) {
      ret.push({
        id: id,
        externalSystem: booking.externalSystem,
        externalOrderId: booking.externalOrderId
      });
      id++;
    }
  }

  if (ret.length === 0) {
    // probably legacy
    for (const externalOrderId of inquiry.legacyBookingNumbers) {
      ret.push({
        id: id,
        externalSystem: "Linkonline",
        externalOrderId: externalOrderId
      });
      id++;
    }
  }

  return ret;
}

function titleString(str) {
  if (!str) {
    return "";
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export {
  copyToClipboard,
  debounce,
  fixUrl,
  getLegacySupportedBookingsFromInquiry,
  makeCopy,
  titleString,
  validator
};
